<template>
    <div>
        <uploadImage ref="uploadImage" id="uploadImage" :width="100" :height="100" v-model="imgSrc" style="display: none" @success="onSuccess"></uploadImage>
        <quill-editor ref="QuillEditor" id="QuillEditor" :options="editorOption" v-model="content"></quill-editor>
    </div>
</template>
<script>
import uploadImage from '@/components/uploadImage';
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import * as Quill from 'quill';
let fontSizeStyle = Quill.import('attributors/style/size');
fontSizeStyle.whitelist = ['12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px', '30px', '32px', '34px', '36px', '38px', '40px', '42px', '44px', '46px', '48px', '50px'];
Quill.register(fontSizeStyle, true);

// let fonts = ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial', 'sans-serif'];
// let Font = Quill.import('formats/font');
// Font.whitelist = fonts;
// Quill.register(Font, true);

export default {
    name: 'editor',
    components: {
        quillEditor,
        uploadImage,
    },
    props: {
        value: {
            type: String,
            required: false,
            default: null,
        },
    },
    watch: {
        content(newValue) {
            this.$emit('input', newValue);
        },
        value(newValue) {
            // console.log('newValue', newValue);
            this.content = newValue;
        },
    },
    mounted() {
        this.content = this.value;
    },
    data() {
        return {
            imgSrc: null,
            editorOption: {
                placeholder: '请在此输入文本',
                modules: {
                    toolbar: {
                        container: [
                            ['bold', 'italic', 'underline'],
                            [{ header: 1 }, { header: 2 }],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ indent: '-1' }, { indent: '+1' }],
                            [{ direction: 'rtl' }],
                            [{ size: fontSizeStyle.whitelist }],
                            [{ header: [1, 2, 3, 4, 5, 6, false] }],
                            [{ color: [] }],
                            [{ font: [] }],
                            [{ align: [] }],
                            ['link', 'image'],
                        ],
                        handlers: {
                            image: function (value) {
                                if (value) {
                                    // 调用element的图片上传组件
                                    console.log(value);
                                    let inputObj = document.querySelector('#uploadImage .upload .ivu-upload-input');
                                    inputObj.focus();
                                    inputObj.click();
                                } else {
                                    this.quill.format('image', false);
                                }
                            },
                        },
                    },
                },
            },
            content: null,
        };
    },
    methods: {
        onSuccess() {
            let quill = this.$refs.QuillEditor.quill;
            // 获取光标所在位置
            let length = quill.getSelection().index;
            // 插入图片，res为服务器返回的图片链接地址
            quill.insertEmbed(length, 'image', this.imgSrc);
            // 调整光标到最后
            quill.setSelection(length + 1);
        },
    },
};
</script>
<style lang="less" scoped>
#QuillEditor {
    max-width: 800px;
}
/deep/ .ql-container.ql-snow {
    min-height: 260px;
}
/deep/ .quill-editor.ql-snow.ql-editor {
    padding: 0;
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='12px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='12px']::before {
    content: '12px';
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='14px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='14px']::before {
    content: '14px';
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='16px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
    content: '16px';
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='18px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='18px']::before {
    content: '18px';
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='20px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='20px']::before {
    content: '20px';
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='22px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='22px']::before {
    content: '22px';
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='24px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='24px']::before {
    content: '24px';
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='26px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='26px']::before {
    content: '26px';
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='28px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='28px']::before {
    content: '28px';
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='30px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='30px']::before {
    content: '30px';
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='32px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='32px']::before {
    content: '32px';
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='34px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='34px']::before {
    content: '34px';
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='36px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='36px']::before {
    content: '36px';
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='38px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='38px']::before {
    content: '38px';
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='40px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='40px']::before {
    content: '40px';
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='42px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='42px']::before {
    content: '42px';
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='44px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='44px']::before {
    content: '44px';
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='46px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='46px']::before {
    content: '46px';
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='48px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='48px']::before {
    content: '48px';
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='50px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='50px']::before {
    content: '50px';
}

// /deep/ .ql-snow .ql-picker.ql-font .ql-picker-label::before,
// /deep/ .ql-snow .ql-picker.ql-font .ql-picker-item::before {
// 	content: '标准字体' !important;
// }
// /deep/ .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='serif']::before,
// /deep/ .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
// 	content: '衬线字体' !important;
// }
// /deep/ .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before,
// /deep/ .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
// 	content: '等宽字体' !important;
// }

// /deep/ .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='SimSun']::before,
// /deep/ .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='SimSun']::before {
// 	content: '宋体' !important;
// 	font-family: 'SimSun';
// }

// /deep/ .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='SimHei']::before,
// /deep/ .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='SimHei']::before {
// 	content: '黑体' !important;
// 	font-family: 'SimHei';
// }

// /deep/ .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Microsoft-YaHei']::before,
// /deep/ .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Microsoft-YaHei']::before {
// 	content: '微软雅黑' !important;
// 	font-family: 'Microsoft YaHei';
// }

// /deep/ .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='KaiTi']::before,
// /deep/ .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='KaiTi']::before {
// 	content: '楷体' !important;
// 	font-family: 'KaiTi';
// }

// /deep/ .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='FangSong']::before,
// /deep/ .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='FangSong']::before {
// 	content: '仿宋' !important;
// 	font-family: 'FangSong';
// }

// /deep/ .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Arial']::before,
// /deep/ .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Arial']::before {
// 	content: 'Arial' !important;
// 	font-family: 'Arial';
// }

// /deep/ .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Times-New-Roman']::before,
// /deep/ .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Times-New-Roman']::before {
// 	content: 'Times New Roman' !important;
// 	font-family: 'Times New Roman';
// }

// /deep/ .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='sans-serif']::before,
// /deep/ .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='sans-serif']::before {
// 	content: 'sans-serif' !important;
// 	font-family: 'sans-serif';
// }

// /deep/ .ql-font-SimSun {
// 	font-family: 'SimSun';
// }

// /deep/ .ql-font-SimHei {
// 	font-family: 'SimHei';
// }

// /deep/ .ql-font-Microsoft-YaHei {
// 	font-family: 'Microsoft YaHei';
// }

// /deep/ .ql-font-KaiTi {
// 	font-family: '楷体';
// }

// /deep/ .ql-font-FangSong {
// 	font-family: 'fangsong';
// }

// /deep/ .ql-font-Arial {
// 	font-family: 'Arial';
// }

// /deep/ .ql-font-Times-New-Roman {
// 	font-family: 'Times New Roman';
// }

// /deep/ .ql-font-sans-serif {
// 	font-family: 'sans-serif';
// }
</style>
